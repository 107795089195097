import classNames from 'classnames'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import EditIcon from '../../../../assets/icons/chord-editor/edit.svg'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { capitalize } from '../../../../utils/stringUtils'
import { getElementFromTree } from '../../../../utils/timelineUtils'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { chordEditorLocalStorageKeys, chordKeyPalette, chordScalePalette } from '../data'
import styles from './ChordEditorComponents.module.scss'

const STRINGS = {
  originalKey: 'Original key',
  otherKey: 'Other key',
  dropdownTitleChord: 'Chord Key',
  dropdownTitleSong: 'Song Key',
  dropdownBtn: 'Use chords of ',
  dropdownBtnDisabled: 'Current key',
  switchBtn: 'b <> #',
}

const triggerId = 'ChordKeyMenuTrigger'
const dropdownId = 'ChordKeyMenuDropdown'

const triggerId_footer = 'ChordKeyMenuTrigger_footer'
const dropdownId_footer = 'ChordKeyMenuDropdown_footer'

type Props = {
  chordKey: string
  chordScale: string
  setKeyScale: (key: string, scale: string) => void
  footerMode?: boolean
}

const getInitialProps = (key: string, scale: string) => {
  if (!key || !scale) return { keyIndex: 0, scaleIndex: 0, paletteSwitched: false }

  const scaleIndex = chordScalePalette.indexOf(scale)
  let keyIndex = 0
  let paletteSwitched = false

  const keyPalette = chordKeyPalette[scale]
  const notSwitchedPalette = keyPalette[false.toString()]
  const switchedPalette = keyPalette[true.toString()]

  if (switchedPalette.includes(key)) {
    paletteSwitched = true
    keyIndex = switchedPalette.indexOf(key)
  }
  if (notSwitchedPalette.includes(key)) {
    paletteSwitched = false
    keyIndex = notSwitchedPalette.indexOf(key)
  }

  return { keyIndex, scaleIndex, paletteSwitched }
}

const ChordKeyMenu: FC<Props> = ({ chordKey, chordScale, setKeyScale, footerMode }) => {
  const { isMobile } = useSizes()

  const dropdownRef = useRef<HTMLDivElement>(null)
  const prevActiveKeyRef = useRef<any>(null)

  const [_, setActiveKeyTypeLocalstorageRaw] = useLocalStorage<'original' | 'other'>(
    chordEditorLocalStorageKeys.inKeyChordScaleType,
    'original',
  )
  const [activeKeyType, setActiveKeyTypeRaw] = useLocalStorage<'original' | 'other' | 'edit'>(
    chordEditorLocalStorageKeys.inKeyChordScaleTypeExtended,
    'original',
  )
  const [isFooterMenuOpened, setIsFooterMenuOpened] = useState(false)

  const [keyPaletteSwitched, setKeyPaletteSwitched] = useState(false)
  const [selectedKeyIndex, setSelectedKeyIndex] = useState(0)
  const [selectedScaleIndex, setSelectedScaleIndex] = useState(0)

  const prevActiveKey = prevActiveKeyRef.current

  const selectedScale = useMemo(() => chordScalePalette[selectedScaleIndex], [selectedScaleIndex])
  const selectedKey = useMemo(
    () => chordKeyPalette[selectedScale][keyPaletteSwitched.toString()][selectedKeyIndex],
    [selectedScale, keyPaletteSwitched, selectedKeyIndex],
  )
  const isCurrentKey = useMemo(() => {
    return (
      chordKey.toLowerCase() === selectedKey.toLowerCase() && chordScale.toLowerCase() === selectedScale.toLowerCase()
    )
  }, [chordKey, chordScale, selectedKey, selectedScale])
  const otherKey = useMemo(() => (chordKey && chordScale ? `${chordKey} ${chordScale}` : ''), [chordKey, chordScale])
  const isDropdownOpen = useMemo(
    () => activeKeyType === 'edit' || (activeKeyType === 'other' && !otherKey),
    [activeKeyType, otherKey],
  )

  useEffect(() => {
    if (activeKeyType !== 'edit') {
      setActiveKeyTypeLocalstorageRaw(activeKeyType as any)
    }
  }, [activeKeyType])
  useEffect(() => {
    if (!footerMode) return
    if (selectedKey === chordKey && selectedScale === chordScale) return

    handleApplyOtherKey(selectedKey, selectedScale)
  }, [selectedKey, selectedScale])
  useEffect(() => {
    if (!isFooterMenuOpened) return
    const { keyIndex, scaleIndex, paletteSwitched } = getInitialProps(chordKey, chordScale)

    setSelectedKeyIndex(keyIndex)
    setSelectedScaleIndex(scaleIndex)
    setKeyPaletteSwitched(paletteSwitched)
  }, [isFooterMenuOpened])

  const setActiveKeyType = (v: string) => {
    prevActiveKeyRef.current = activeKeyType
    setActiveKeyTypeRaw(v as any)
  }

  useClickOutsideAlerter(
    dropdownRef,
    () => {
      if (footerMode && isFooterMenuOpened) {
        setIsFooterMenuOpened(false)
      }
      if (!footerMode && isDropdownOpen) {
        prevActiveKey && setActiveKeyType(prevActiveKey)
      }
    },
    undefined,
    undefined,
    (event) => !getElementFromTree(event, (e) => (footerMode ? e?.id === triggerId_footer : e?.id === triggerId)),
    undefined,
    [footerMode, prevActiveKey, isDropdownOpen, isFooterMenuOpened],
  )

  const handleChangeKeyPaletteType = () => {
    setKeyPaletteSwitched((keyPaletteTypeV) => !keyPaletteTypeV)
  }
  const handleApplyOtherKey = (key?: string, scale?: string) => {
    if (footerMode && !key && !scale) return
    if (!footerMode && isCurrentKey) return

    setKeyScale(key || selectedKey, scale || selectedScale)

    if (!footerMode) {
      setActiveKeyType('other')
      setIsFooterMenuOpened(false)
    }
  }
  const handleChangeKey = (key: string, index: number) => {
    if (selectedKeyIndex === index) return

    setSelectedKeyIndex(index)
  }
  const handleChangeScale = (scale: string, index: number) => {
    if (selectedScaleIndex === index) return

    setSelectedScaleIndex(index)
    setKeyPaletteSwitched(false)
  }

  const checkIfKeyIsActive = (index: number) => {
    const notSwitchedKey = chordKeyPalette[selectedScale]['false'][index]
    const switchedKey = chordKeyPalette[selectedScale]['true'][index]

    return notSwitchedKey === selectedKey || switchedKey === selectedKey
  }

  const renderTriggers = () => {
    if (footerMode) {
      return (
        <div
          className={classNames(styles.chordKeyMenuTriggerFooter, {
            [styles.chordKeyMenuTriggerFooterActive]: isFooterMenuOpened,
          })}
          onClick={() => setIsFooterMenuOpened((v) => !v)}
          id={triggerId_footer}
          data-tooltip-id={dropdownId_footer}
        >
          <div className={styles.chordKeyMenuTriggerFooterValue}>{chordKey}</div>
          <div className={styles.chordKeyMenuTriggerFooterName}>{chordScale}</div>
        </div>
      )
    }
    return (
      <div className={styles.chordKeyMenuTrigger} id={triggerId}>
        <div className={styles.chordKeyMenuTriggerRow}>
          <div
            className={classNames(styles.chordKeyMenuTriggerButton, {
              [styles.chordKeyMenuTriggerButtonActive]: activeKeyType === 'original',
            })}
            onClick={() => setActiveKeyType('original')}
          >
            {STRINGS.originalKey}
          </div>
        </div>

        <div className={styles.chordKeyMenuTriggerRow} data-tooltip-id={dropdownId}>
          <div
            className={classNames(styles.chordKeyMenuTriggerButton, {
              [styles.chordKeyMenuTriggerButtonActive]: activeKeyType === 'other',
            })}
            onClick={() => setActiveKeyType('other')}
          >
            {otherKey || STRINGS.otherKey}
          </div>

          {otherKey && (
            <div
              className={classNames(styles.chordKeyMenuTriggerButton, {
                [styles.chordKeyMenuTriggerButtonActive]: activeKeyType === 'edit',
              })}
              onClick={() => setActiveKeyType('edit')}
            >
              <EditIcon />
            </div>
          )}
        </div>
      </div>
    )
  }
  const renderActions = () => {
    if (footerMode) return null
    return (
      <div className={styles.chordKeyMenuDropdownActions}>
        <div
          className={classNames(styles.chordKeyMenuDropdownBtn, {
            [styles.chordKeyMenuDropdownBtnDisabled]: isCurrentKey,
          })}
          onClick={() => handleApplyOtherKey()}
        >
          {isCurrentKey ? STRINGS.dropdownBtnDisabled : `${STRINGS.dropdownBtn} "${selectedKey} ${selectedScale}"`}
        </div>
      </div>
    )
  }
  const renderDropdown = () => {
    return (
      <div
        ref={dropdownRef}
        data-disable-ids-click-outside-hook={[clickOutsideHookIds.timelineWrapper, clickOutsideHookIds.footer]}
      >
        <div className={styles.chordKeyMenuDropdownBody}>
          <div className={styles.chordKeyMenuDropdownHeader}>
            <div className={styles.chordKeyMenuDropdownTitle}>
              {footerMode ? STRINGS.dropdownTitleSong : STRINGS.dropdownTitleChord}
            </div>

            <div
              className={classNames(styles.chordKeyMenuDropdownTypeBtn, {
                [styles.chordKeyMenuDropdownTypeBtnActive]: keyPaletteSwitched,
              })}
              onClick={handleChangeKeyPaletteType}
            >
              {STRINGS.switchBtn}
            </div>
          </div>

          <div className={styles.chordKeyMenuDropdownContent}>
            {chordKeyPalette[selectedScale][keyPaletteSwitched.toString()].map((key, index) => (
              <div
                key={`key_${index}`}
                className={classNames(styles.chordKeyMenuDropdownKey, {
                  [styles.chordKeyMenuDropdownActiveKey]: checkIfKeyIsActive(index),
                })}
                onClick={() => handleChangeKey(key, index)}
              >
                {key}
              </div>
            ))}
          </div>

          <div className={styles.chordKeyMenuDropdownFooter}>
            {chordScalePalette.map((scale, index) => (
              <div
                key={scale}
                className={classNames(styles.chordKeyMenuDropdownScale, {
                  [styles.chordKeyMenuDropdownActiveScale]: scale === selectedScale,
                })}
                onClick={() => handleChangeScale(scale, index)}
              >
                {capitalize(scale)}
              </div>
            ))}
          </div>
        </div>
        {renderActions()}
      </div>
    )
  }

  if (isMobile) return renderDropdown()
  return (
    <>
      {renderTriggers()}

      <Tooltip
        className={classNames(styles.chordKeyMenuDropdown, { [styles.chordKeyMenuDropdownFooterMode]: footerMode })}
        id={footerMode ? dropdownId_footer : dropdownId}
        place={footerMode ? 'top' : 'left'}
        isOpen={footerMode ? isFooterMenuOpened : isDropdownOpen}
        clickable
      >
        {renderDropdown()}
      </Tooltip>
    </>
  )
}

export default ChordKeyMenu
