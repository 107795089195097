import { ViewConfig } from '../../../utils/types'

type SimpleProps = (
  menuOpened: boolean,
  heightChange: number,
  sizes: { isMobile: boolean | null; isSmallDesktop: boolean | null },
) => number
type Props = (view: ViewConfig, sizes: { isMobile: boolean | null; isSmallDesktop: boolean | null }) => number

export const getChordsEditorHeight: Props = (view, { isMobile, isSmallDesktop }) => {
  if (view.editorOpen) {
    return 250
  }
  if (view.layersOpen) {
    if (isMobile) return 420
    return 250
  }
  return 0
}
export const getChordLayersHeight: SimpleProps = (menuOpened, heightChange, { isMobile, isSmallDesktop }) => {
  if (!menuOpened) return 0
  if (isMobile) return 420
  return 250
}
export const getChordEditorHeight: SimpleProps = (menuOpened, heightChange, { isMobile, isSmallDesktop }) => {
  if (!menuOpened) return 0
  if (isMobile) return 476
  return 290 + heightChange
}
export const getMelodyEditorHeight: SimpleProps = (menuOpened, heightChange, { isMobile, isSmallDesktop }) => {
  if (!menuOpened) return 0
  if (isMobile) return 262
  return 478 + heightChange
}
export const getDrumsEditorHeight: SimpleProps = (menuOpened, heightChange, { isMobile, isSmallDesktop }) => {
  if (!menuOpened) return 0
  if (isMobile) return 476
  return 416 + heightChange
}
export const getInstrumentHeight: Props = (view, { isMobile, isSmallDesktop }) => {
  if (view.guitarOpen || view.notationOpen) {
    return 165
  }
  if (view.pianoOpen) {
    if (isMobile) return 90
    if (isSmallDesktop) return 135
    return 165
  }
  return 0
}
