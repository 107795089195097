import classNames from 'classnames'
import React from 'react'

import SettingsIcon from '../../../assets/icons/chord-edit.svg'
import CommentsIcon from '../../../assets/icons/comments.svg'
import DrumsEditorIcon from '../../../assets/icons/drum.svg'
import GuitarIcon from '../../../assets/icons/guitar-fret.svg'
import InstrumentsIcon from '../../../assets/icons/instruments.svg'
import MicrophoneIcon from '../../../assets/icons/microphone.svg'
import PianoIcon from '../../../assets/icons/piano-roll.svg'
import ChordsEditorIcon from '../../../assets/icons/piano.svg'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import useSizes from '../../../hooks/useSizes'
import routes from '../../../utils/routes'
import { windowOpen } from '../../../utils/stringUtils'
import DropdownMenu from '../../common/DropdownMenu/DropdownMenu'
import IconMenuButton from '../../common/IconMenuButton'
import { INSTRUMENT_TYPES } from '../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './Launchpad.module.scss'

type Props = {
  onClose?: () => void
}

const Launchpad: React.FC<Props> = ({ onClose }) => {
  const modalsConfig = useCommonModals()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const generatorModalsConfig = useGeneratorModals()
  const { currentUser } = useCurrentUser()
  const { isMobile } = useSizes()
  const { text } = useInternationalization()

  const STRINGS = text.footer.launchpad

  return (
    <div>
      <DropdownMenu
        mobileView={isMobile}
        options={[
          [
            ...(isMobile
              ? [
                  {
                    content: (
                      <div>
                        <span>{STRINGS.aiChords}</span>
                        <p>{STRINGS.aiChordsDesc}</p>
                      </div>
                    ),
                    className: classNames(styles.dropdownOption, styles.lightBlue),
                    key: 9,
                    disabled:
                      playerConfig.view.drumsEditorOpen || !playerConfig.currentPart || playerConfig.currentPart?.draft,
                    icon: <ChordsEditorIcon />,
                    onClick: () => {
                      generatorModalsConfig.setIsChordsSettingsOpen(true)
                      onClose && onClose()
                    },
                  },
                  {
                    content: (
                      <div>
                        <span>{STRINGS.aiDrums}</span>
                        <p>{STRINGS.aiDrumsDesc}</p>
                      </div>
                    ),
                    className: classNames(styles.dropdownOption, styles.green),
                    key: 8,
                    disabled:
                      playerConfig.view.drumsEditorOpen || !playerConfig.currentPart || playerConfig.currentPart?.draft,
                    icon: <DrumsEditorIcon />,
                    onClick: () => {
                      if (!playerConfig.currentPart.drumsShown) playerConfigSetter.handleShowHideDrums()

                      generatorModalsConfig.setIsDrumsSettingsOpen(true)
                      onClose && onClose()
                    },
                  },
                ]
              : []),
            ...(isMobile
              ? []
              : [
                  {
                    content: (
                      <div>
                        <span>{STRINGS.lyrics}</span>
                        <p>{STRINGS.lyricsDesc}</p>
                      </div>
                    ),
                    className: classNames(styles.dropdownOption, styles.yellow),
                    key: 5,
                    disabled: playerConfig.view.lyricsOpen,
                    icon: <MicrophoneIcon />,
                    onClick: () => {
                      playerConfigSetter.setView({ lyricsOpen: true })
                      onClose && onClose()
                    },
                  },
                ]),
            {
              content: (
                <div>
                  <span>{STRINGS.instruments}</span>
                  <p>{STRINGS.instrumentsDesc}</p>
                </div>
              ),
              disabled: !!playerConfig.view.layersOpen,
              className: classNames(styles.dropdownOption, styles.blue),
              key: 12,
              icon: <InstrumentsIcon className={styles.noStroke} />,
              blank: true,
              onClick: () => {
                playerConfigSetter.setView({ layersOpen: INSTRUMENT_TYPES.CHORDS, pianoOpen: true })
                onClose && onClose()
              },
            },
            {
              content: (
                <div>
                  <span>{STRINGS.melodyEditor}</span>
                  <p>{STRINGS.melodyEditorDesc}</p>
                </div>
              ),
              className: classNames(styles.dropdownOption, styles.blue),
              key: 2,
              icon: <PianoIcon />,
              disabled: playerConfig.view.melodyEditorOpen,
              onClick: () => {
                if (!playerConfig.currentPart.melodyShown) playerConfigSetter.handleShowHideMelody()

                playerConfigSetter.setView({ melodyEditorOpen: true })
                onClose && onClose()
              },
            },
            ...(isMobile
              ? []
              : [
                  {
                    content: (
                      <div>
                        <span>{STRINGS.drumsEditor}</span>
                        <p>{STRINGS.drumsEditorDesc}</p>
                      </div>
                    ),
                    className: classNames(styles.dropdownOption, styles.green),
                    key: 7,
                    disabled: playerConfig.view.drumsEditorOpen,
                    icon: <DrumsEditorIcon />,
                    onClick: () => {
                      playerConfigSetter.setView({ drumsEditorOpen: true, drumsOpen: true })
                      onClose && onClose()
                    },
                  },
                ]),
            {
              content: (
                <div>
                  <span>{STRINGS.guitar}</span>
                  <p>{STRINGS.guitarDesc}</p>
                </div>
              ),
              disabled: playerConfig.view.guitarOpen,
              className: classNames(styles.dropdownOption),
              key: 11,
              icon: <GuitarIcon className={styles.noStroke} />,
              onClick: () => {
                playerConfigSetter.setView({ pianoOpen: false, guitarOpen: true })
                onClose && onClose()
              },
            },
            {
              content: (
                <div>
                  <span>{STRINGS.chordEdit}</span>
                  <p>{STRINGS.chordEditDesc}</p>
                </div>
              ),
              className: classNames(styles.dropdownOption),
              key: 3,
              disabled: playerConfig.view.editorOpen || isMobile,
              icon: <SettingsIcon className={styles.noStroke} />,
              onClick: () => {
                playerConfigSetter.setView({ editorOpen: true })
                onClose && onClose()
              },
            },
            {
              content: (
                <div>
                  <span>{STRINGS.feedback}</span>
                  <p>{STRINGS.feedbackDesc}</p>
                </div>
              ),
              className: styles.dropdownOption,
              key: 6,
              icon: <CommentsIcon />,
              blank: true,
              onClick: () => {
                if (!currentUser) {
                  modalsConfig.setLoginOpen(true)
                  return
                }
                if (playerConfig.isPlaying) {
                  playerConfig.player.pause()
                }
                onClose && onClose()
                windowOpen(routes.FEEDBACK_FRILL)
              },
            },
          ].filter((item) => !item.disabled),
        ]}
        className={classNames(styles.dropdownMenu, {
          [styles.lyricsOpen]: playerConfig.view.lyricsOpen,
        })}
        renderTrigger={(onClick) => (
          <IconMenuButton
            onClick={onClick}
            icon='launchpad'
            text={playerConfig.view.lyricsOpen ? undefined : STRINGS.launchpad}
            className={classNames(styles.launchpadButton, {
              [styles.lyricsOpen]: playerConfig.view.lyricsOpen,
            })}
          />
        )}
        generatorComponent
      />
    </div>
  )
}

export default Launchpad
